export const useBlockquotes = () => {
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();

  async function all() {
    const { data } = await storyblokApi.get("cdn/stories", {
      ...storyblokApiOptions,
      starts_with: "zitate/",
      is_startpage: 0,
    });

    return data.stories;
  }

  return {
    all,
  };
};
